<template>
  <form @submit.prevent="submitCuentaBanco" class="cuentaBanco">
    <div class="text-end text-light">
      <Cerrar />
    </div>

    <div class="row">
      <div class="col-md-12">
        <label class="text-light">Tipo</label>
        <select
          :disabled="objCuentaBanco.id"
          class="form-select"
          aria-label="Default select example"
          v-model="objCuentaBanco.tipo"
          @change="changeTipo"
        >
          <option value="m">PagoMovil</option>
          <option value="c">Cuenta</option>
        </select>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-md-12">
        <label class="text-light">Banco</label>
        <select
          class="form-select"
          aria-label="Default select example"
          v-model="objCuentaBanco.codigo"
          @change="selecBanco"
        >
          <option
            v-for="b of lstBancos"
            v-bind:key="b.codigo"
            :value="b.codigo"
          >
            {{ b.codigo }} - {{ b.banco }}
          </option>
        </select>
        <small class="form-text txt-red" v-if="v$.codigo.$error">
          *Debe seleccionar un banco.
        </small>
      </div>
    </div>

    <div class="row mt-4" v-if="objCuentaBanco.tipo == 'c'">
      <div class="col-md-12">
        <label class="text-light">Nro Cuenta</label>
        <input
          v-model="objCuentaBanco.nro_cuenta"
          type="text"
          class="form-control"
          v-maska="'####-#########-#######'"
        />
        <small class="form-text txt-red" v-if="v$.nro_cuenta.$error">
          *Debe agregar un numero de cuenta.
        </small>
      </div>
    </div>
    <div class="row mt-4" v-if="objCuentaBanco.tipo == 'm'">
      <div class="col-md-12">
        <label class="text-light">Teléfono</label>
        <input
          v-model="objCuentaBanco.telefono"
          type="text"
          class="form-control"
          v-maska="'####-###-##-##'"
        />
        <small class="form-text txt-red" v-if="v$.telefono.$error">
          *Debe agregar un numero de telefono.
        </small>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-md-3">
        <label class="text-light">Doc Tipo </label>
        <select
          class="form-select"
          aria-label="Default select example"
          v-model="docTipo"
        >
          <option value="V">Cedula</option>
          <option value="J">Rif</option>
        </select>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-md-12">
        <label class="text-light">Documento</label>
        <input
          v-if="docTipo == 'V'"
          v-model="objCuentaBanco.documento"
          type="text"
          class="form-control"
          v-maska="'V-########'"
        />
        <input
          v-if="docTipo == 'J'"
          v-model="objCuentaBanco.documento"
          type="text"
          class="form-control"
          v-maska="'J-##########'"
        />
        <small class="form-text txt-red" v-if="v$.documento.$error">
          *Debe agregar un numero de documento.
        </small>
      </div>
    </div>

    <div class="row justify-content-center py-3">
      <div class="col-md-4">
        <img
          :src="imageUrl || 'img/no-img.jpg'"
          alt=""
          id="img"
          class="img-fluid"
          @click="$refs.file.click()"
          style="cursor: pointer"
        />
        <input
          class="d-none"
          ref="file"
          type="file"
          accept=".png, .jpg, .jpeg"
          @change="onChange"
        />
      </div>
    </div>

    <div class="row justify-content-end py-3">
      <div class="col-md-2">
        <div class="form-check form-switch">
          <input
            v-model="objCuentaBanco.status"
            class="form-check-input"
            type="checkbox"
            checked
          />
          <label
            v-if="objCuentaBanco.status"
            class="form-check-label text-light"
            >Activo</label
          >
          <label v-else class="form-check-label text-light">Inactivo</label>
        </div>
      </div>
    </div>

    <div class="row justify-content-center">
      <div class="col-md-2">
        <button class="myButtonAceptar">Guardar</button>
      </div>
    </div>
  </form>
</template>

<script>
import { inject, onBeforeUnmount, onMounted, ref } from "vue-demi";
import useVuelidate from "@vuelidate/core";
import { required, minLength, maxLength } from "@vuelidate/validators";
import { useStore } from "vuex";
import Cerrar from "../../../components/button/Cerrar.vue";
import bancos from "../../../store/data/bancos.json";
import Swal from "sweetalert2";
import useBanco from "../composable/useBanco";

export default {
  components: {
    Cerrar,
  },
  setup() {
    const btn = inject("btn");
    const objCuentaBanco = inject("objCuentaBanco");
    const imageUrl = inject("imageUrl");
    const store = useStore();
    const previewImage = ref("");
    const lstBancos = ref(bancos);
    const docTipo = ref("V");
    const { getBanco, createCuentaBanco, editCuentaBanco } = useBanco();

    const rules = {
      banco: {
        required,
      },
      codigo: {
        required,
      },
      telefono: {
        required,
      },
      documento: {
        required,
      },
      nro_cuenta: {
        required,
        minLength: minLength(22),
        maxLength: maxLength(22),
      },
    };

    const v$ = useVuelidate(rules, objCuentaBanco);

    const selecBanco = (e) => {
      objCuentaBanco.banco = lstBancos.value.find(
        (b) => b.codigo == e.target.value
      ).banco;
    };

    onMounted(() => {
      if (objCuentaBanco.id) {
        objCuentaBanco.banco = lstBancos.value.find(
          (b) => b.codigo == objCuentaBanco.codigo
        ).banco;
        docTipo.value = objCuentaBanco.documento.substring(0,1);        
      }
    });

    const cerrar = () => {
      btn.value = false;
    };

    const submitCuentaBanco = async () => {
      v$.value.$touch();
      if (v$.value.$invalid) {
        console.log("ERRRR", objCuentaBanco);
        return false;
      }

      let resp = "";
      store.commit("showLoader");
      if (objCuentaBanco.id) {
        resp = await editCuentaBanco(objCuentaBanco);
      } else {
        resp = await createCuentaBanco(objCuentaBanco);
      }
      await getBanco();
      store.commit("hideLoader");
      if (resp.code == 200) {
        Swal.fire("Excelente!", resp.message, "success");
      } else {
        Swal.fire("Error!", resp.message, "error");
      }

      cerrar();
    };

    const onChange = (e) => {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        previewImage.value = e.target.result;
        objCuentaBanco.img = previewImage.value;
      };
      imageUrl.value = URL.createObjectURL(file);
    };

    onBeforeUnmount(() => {
      objCuentaBanco.id = null;
      objCuentaBanco.nro_cuenta = "";
      objCuentaBanco.banco = "";
      objCuentaBanco.tipo = "c";
      objCuentaBanco.telefono = "";
      objCuentaBanco.documento = "";
      objCuentaBanco.status = true;
      objCuentaBanco.deleted = false;
      objCuentaBanco.img = null;
      imageUrl.value = null;
      btn.value = false;
    });

    const changeTipo = (e) => {
      if (e.target.value == "m") {
        objCuentaBanco.telefono = "";
        objCuentaBanco.nro_cuenta = "0000-000000000-0000000";
      } else {
        objCuentaBanco.telefono = "00000000000000";
        objCuentaBanco.nro_cuenta = "";
      }
    };

    return {
      cerrar,
      objCuentaBanco,
      submitCuentaBanco,
      v$,
      onChange,
      imageUrl,
      lstBancos,
      selecBanco,
      changeTipo,
      docTipo,
    };
  },
};
</script>

<style scoped>
.cuentaBanco {
  background: #212529;
  -webkit-box-shadow: 10px 10px 28px 17px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 28px 17px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 28px 17px rgba(0, 0, 0, 0.75);
  padding: 20px;
  width: 40em;
  margin: auto;
}

@media (max-width: 1000px) {
  .cuentaBanco {
    width: 100%;
  }
}
</style>