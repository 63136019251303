<template>
  <div class="text-end mt-2 mb-2">
    <button class="myButtonAceptar" @click="agregarCuentaBanco">Agregar</button>
  </div>

  <div class="card table-responsive-sm">
    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th scope="col">Codigo</th>
          <th scope="col">Banco</th>
          <th scope="col">NRO Cuenta</th>
          <th scope="col">Teléfono</th>
          <th scope="col">Status</th>
          <th scope="col">Tipo</th>
          <th scope="col">Eliminar</th>
        </tr>
      </thead>
      <tbody v-for="d of lstCuentaBanco" v-bind:key="d.id">
        <tr style="cursor: pointer">
          <td @click="select(d)">{{ d.codigo }}</td>
          <td @click="select(d)">{{ d.banco }}</td>
          <td @click="select(d)">{{ d.nro_cuenta }}</td>
          <td @click="select(d)">{{ d.telefono }}</td>
          <td @click="select(d)">{{ validarStatus(d.status) }}</td>
          <td @click="select(d)">{{ validarTipo(d.tipo) }}</td>
          <td>
            <button class="btnEliminarTable" @click="eliminarCuenta(d)">
              <i style="color: #fff" class="far fa-trash-alt"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <!-- <Paginador
      :data="lstCuentaBanco.links"
      endpoint="/api/v1/getCuentaBanco"
      set="setCuentaBanco"
      parametros=""
    /> -->
  </div>
</template>

<script>
import { inject } from "vue-demi";
import Swal from "sweetalert2";
import { useStore } from "vuex";
// import Paginador from "../Paginador.vue";
import useBanco from "../composable/useBanco";
export default {
  name: "CuentaBancoTables",
  props: ["lstCuentaBanco"],
  components: {
    // Paginador,
  },
  setup() {
    const objCuentaBanco = inject("objCuentaBanco");
    const btn = inject("btn");
    const store = useStore();
    const imageUrl = inject("imageUrl");
    const { getBanco, deleteCuentaBanco } = useBanco();

    const agregarCuentaBanco = () => {
      objCuentaBanco.id = null;
      objCuentaBanco.nro_cuenta = "";
      objCuentaBanco.banco = "";
      objCuentaBanco.status = true;
      objCuentaBanco.deleted = false;
      objCuentaBanco.img = null;
      objCuentaBanco.tipo = "c";
      objCuentaBanco.documento = "";
      objCuentaBanco.telefono = "0412-999-99-99";
      objCuentaBanco.codigo = null;
      btn.value = true;
    };

    const select = (e) => {
      objCuentaBanco.id = e.id;
      objCuentaBanco.nro_cuenta = e.nro_cuenta;
      objCuentaBanco.banco = e.banco;
      objCuentaBanco.status = e.status;
      objCuentaBanco.deleted = e.deleted;
      objCuentaBanco.img = e.img;
      objCuentaBanco.tipo = e.tipo;
      objCuentaBanco.documento = e.documento;
      objCuentaBanco.telefono = e.telefono;
      objCuentaBanco.codigo = e.codigo;
      imageUrl.value = e.img ? process.env.VUE_APP_URL_IMG + e.img : null;
      btn.value = true;      
    };

    const validarStatus = (e) => {
      if (e) {
        return "Activo";
      }
      return "Inactivo";
    };
    const validarTipo = (e) => {
      if (e == "m") {
        return "PagoMovil";
      }
      return "Cuenta";
    };

    const eliminarCuenta = (e) => {
      Swal.fire({
        title: "¿Seguro que desea eliminar este registro?",
        showCancelButton: true,
        confirmButtonText: "Si",
      }).then(async (result) => {
        if (result.isConfirmed) {
          objCuentaBanco.id = e.id;
          store.commit("showLoader");
          await deleteCuentaBanco(objCuentaBanco);
          await getBanco();
          store.commit("hideLoader");
          return true;
        }
      });
    };

    return {
      agregarCuentaBanco,
      select,
      eliminarCuenta,
      validarStatus,
      validarTipo,
    };
  },
};
</script>

<style>
</style>