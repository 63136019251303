import { computed } from "vue";
import { useStore } from "vuex";

const useBanco = () => {
  const store = useStore();

  const createCuentaBanco = async (banco) => {
    const resp = await store.dispatch("banco/createCuentaBanco", banco);
    return resp;
  };
  const editCuentaBanco = async (banco) => {
    const resp = await store.dispatch("banco/editCuentaBanco", banco);
    return resp;
  };
  const deleteCuentaBanco = async (banco) => {
    const resp = await store.dispatch("banco/deleteCuentaBanco", banco);
    return resp;
  };
  const getBanco = async () => {
    const resp = await store.dispatch("banco/getBanco");
    return resp;
  };


  return {
    createCuentaBanco,
    editCuentaBanco,
    deleteCuentaBanco,
    getBanco,
    lstCuentaBanco: computed(() => store.getters["banco/getCuentaBanco"]),    
  };
};

export default useBanco;
