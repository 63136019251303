<template>
  <div class="container-fluid">
    <h3 class="text-light mt-3">Cuenta Bancarias</h3>
    <div class="row">
      <div v-if="!btn" class="col-12">
        <CuentaBancoTables :lstCuentaBanco="lstCuentaBanco" />
      </div>
      <div v-if="btn" class="col-12"><CuentaBancoForm /></div>
    </div>
  </div>
</template>

<script>
import { inject, onMounted, provide, reactive } from "vue-demi";
import CuentaBancoForm from "../components/CuentaBancoForm.vue";
import CuentaBancoTables from "../components/CuentaBancoTables.vue";
import { useStore } from "vuex";
import useBanco from "../composable/useBanco";
export default {
  components: {
    CuentaBancoTables,
    CuentaBancoForm,
  },

  setup() {
    const btn = inject("btn");
    const store = useStore();
    const { getBanco, lstCuentaBanco } = useBanco();
    const boxBanco = reactive({
      activo: 0,
      inactivo: 0,
      total: 0,
    });
    const objCuentaBanco = reactive({
      id: null,
      nro_cuenta: "",
      banco: "",
      status: true,
      deleted: false,
      img: null,
      tipo: "m",
      documento: "",
      codigo: null,
    });

    onMounted(async () => {
      store.commit("showLoader");
      await getBanco();
      store.commit("hideLoader");
    });  

    provide("objCuentaBanco", objCuentaBanco);

    return {
      btn,
      lstCuentaBanco,
      boxBanco,
    };
  },
};
</script>

<style>
</style>